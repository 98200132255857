/* eslint import/no-webpack-loader-syntax: off */

import React from "react";
import Logo from "-!react-svg-loader!../../src/assests/svgs/Logo.svg";

/**
 *Header section contains brand logo and description.
 *
 *
 * @author [Tapas Dhar](https://srclogix.com/about/)
 *
 */

const Header = () => {
  return (
    <header className="c-header-wr">
      <div className="container-fluid">
        <div className="row">
          <div className="col-6">
            <div className="c-logo-wr">
              {" "}
              <a href="/" title="logo">
             
                <Logo />
             
              </a>{" "}
              <span>
                <picture>
                  <source
                    srcSet="img/header-bg.webp"
                    type="image/webp"
                    alt="Toktown"
                  />
                  <source srcSet="img/header-bg.jpg" type="image/jpg" alt="TokTown" />
                  <img src="img/header-bg.jpg" alt="TokTown" />{" "}
                </picture>
              </span>{" "}
            </div>
          </div>
          <div className="col-6">
            <div className="c-setting-wr d-flex justify-content-end">
              {" "}
              <a href="/" title="setting btn" >  {/*onclick=modelFunction() */}
                {/* <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                  <path d="m499.95 197.7-39.352-8.5547c-3.4219-10.477-7.6602-20.695-12.664-30.539l21.785-33.887c3.8906-6.0547 3.0352-14.004-2.0508-19.09l-61.305-61.305c-5.0859-5.0859-13.035-5.9414-19.09-2.0508l-33.887 21.785c-9.8438-5.0039-20.062-9.2422-30.539-12.664l-8.5547-39.352c-1.5273-7.0312-7.7539-12.047-14.949-12.047h-86.695c-7.1953 0-13.422 5.0156-14.949 12.047l-8.5547 39.352c-10.477 3.4219-20.695 7.6602-30.539 12.664l-33.887-21.785c-6.0547-3.8906-14.004-3.0352-19.09 2.0508l-61.305 61.305c-5.0859 5.0859-5.9414 13.035-2.0508 19.09l21.785 33.887c-5.0039 9.8438-9.2422 20.062-12.664 30.539l-39.352 8.5547c-7.0312 1.5312-12.047 7.7539-12.047 14.949v86.695c0 7.1953 5.0156 13.418 12.047 14.949l39.352 8.5547c3.4219 10.477 7.6602 20.695 12.664 30.539l-21.785 33.887c-3.8906 6.0547-3.0352 14.004 2.0508 19.09l61.305 61.305c5.0859 5.0859 13.035 5.9414 19.09 2.0508l33.887-21.785c9.8438 5.0039 20.062 9.2422 30.539 12.664l8.5547 39.352c1.5273 7.0312 7.7539 12.047 14.949 12.047h86.695c7.1953 0 13.422-5.0156 14.949-12.047l8.5547-39.352c10.477-3.4219 20.695-7.6602 30.539-12.664l33.887 21.785c6.0547 3.8906 14.004 3.0391 19.09-2.0508l61.305-61.305c5.0859-5.0859 5.9414-13.035 2.0508-19.09l-21.785-33.887c5.0039-9.8438 9.2422-20.062 12.664-30.539l39.352-8.5547c7.0312-1.5312 12.047-7.7539 12.047-14.949v-86.695c0-7.1953-5.0156-13.418-12.047-14.949zm-152.16 58.297c0 50.613-41.18 91.793-91.793 91.793s-91.793-41.18-91.793-91.793 41.18-91.793 91.793-91.793 91.793 41.18 91.793 91.793z" />
                </svg> */}
              </a>{" "}
              <span>
                {/* <picture>
                  <source
                    srcSet="img/setting-bg.webp"
                    type="image/webp"
                    alt=""
                  />
                  <source srcSet="img/setting-bg.jpg" type="image/jpg" alt="" />
                  <img src="img/setting-bg.jpg" alt="" />{" "}
                </picture> */}
              </span>{" "}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
